import React, { useEffect } from 'react';
import bemCn from 'bem-cn';
import { omit } from '../../utils';
import { states } from '../../consts/states';
import CookieService from '../../services/cookie.service';
import { TOP_CITIES } from '../../consts/top-cities';
import { EVENTS_NAME } from '../../consts/event.consts';
import useMobileScreen from '../../services/hooks/useMobileScreen';
import { RouteFormatter } from '../../routes';
import backgroundMobile from '../../assets/img/sniff-seo-bg-header-m.jpg?sizes[]=500';
import background from '../../assets/img/sniff-seo-bg-header-d.png?sizes[]=1200,sizes[]=1920';
import { getSearchFiltersForServer } from '../listings/utils';
import { getCountSearchFilters } from '../../components/search/utils';
import { COOKIE_PARAM_NAME } from '../../consts/cookies.consts';
import { SF_SIZE_OPTIONS } from '../../consts/search-filter.const';
import DesignedForPrivate from '../../components/designed-for-private';
import RenderDetailedHome from './render-detailed-home';
import SniffButton from '../../components/sniff-button';
import BigPicture from '../../components/big-picture';
import './style.scss';

const b = bemCn('home');

const HomePage = ({ useStateSSR, useEffectSSR, useRefSSR, homePageSSR, useMutateTrackEvent, serverSSR }) => {
    const isMobile = useMobileScreen();
    const { trackEvent } = useMutateTrackEvent ? useMutateTrackEvent() : {};
    const [mainTitleClass, setMainTitleClass] = useStateSSR('snif-l2 text-white text-center');

    useEffect(() => {
        if (useMutateTrackEvent) {
            trackEvent({ name: EVENTS_NAME.home_view });
        }
    }, [useMutateTrackEvent]);

    const handleSubClick = (e, opt) => {
        e?.preventDefault();
        const filtersNew = getSearchFiltersForServer(opt);
        const count = 1;
        const filters = omit(filtersNew, ['availability', 'openNow']);
        CookieService.set(COOKIE_PARAM_NAME.SEARCH_FILTERS, JSON.stringify({ filters, count }), { expires: 365 });
        if (isMobile) {
            window.open(RouteFormatter.listings({}));
        } else {
            window.open(RouteFormatter.listings({}), '_blank');
        }
    };

    const handleExplore = (type) => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        if (type === 'Size') {
            const filtersNew = getSearchFiltersForServer({ showSize: true, minimumSize: SF_SIZE_OPTIONS[1] });
            const count = getCountSearchFilters(filtersNew);
            const filters = omit(filtersNew, ['availability', 'openNow']);
            CookieService.set(COOKIE_PARAM_NAME.SEARCH_FILTERS, JSON.stringify({ filters, count }), { expires: 365 });
        }
        window.open(RouteFormatter.listings({}));
    };

    const handleSubStatsClick = (type) => {
        const filtersNew = getSearchFiltersForServer({});
        filtersNew.amenities = [type];
        const count = getCountSearchFilters(filtersNew);
        const filters = omit(filtersNew, ['availability', 'openNow']);
        CookieService.set(COOKIE_PARAM_NAME.SEARCH_FILTERS, JSON.stringify({ filters, count }), { expires: 365 });
        window.open(RouteFormatter.listings({}));
    };

    useEffect(() => {
        if (!isMobile) {
            setMainTitleClass('snif-xl snif-semibold');
        }
    }, [isMobile]);

    return (
        <>
            <div className={b()}>
                <div className="main-action-title">
                    <BigPicture
                        background={background}
                        backgroundSm={backgroundMobile}
                        noBackground={true}
                        right={true}
                        height={600}
                        heightSm={360}
                        alt="Sniffspot main banner"
                    >
                        <h1 className={mainTitleClass}>Rent safe and private dog parks hosted by locals</h1>
                        <a href={RouteFormatter.listings({})}>
                            <SniffButton className="main-action-button" size="lg">
                                Explore spots near me
                            </SniffButton>
                        </a>
                    </BigPicture>
                    <DesignedForPrivate banner />
                </div>
                <RenderDetailedHome
                    SSR
                    {...{
                        isMobile,
                        cities: TOP_CITIES,
                        states,
                        handleSubClick,
                        handleExplore,
                        handleSubStatsClick,
                        useStateSSR,
                        useEffectSSR,
                        useRefSSR,
                        homePageSSR,
                        serverSSR,
                    }}
                />
            </div>
        </>
    );
};

export default HomePage;
